import { t } from '@lingui/macro'
import { useForm } from 'react-hook-form'
import { Button } from '@/src/stories/Button'
import { Spinner } from '@/src/ui'
import { useOrgContext } from '@/hooks/state/organization'
import { Team, TeamInsert } from '@/types'
import Select, { SelectItem } from '@/src/stories/Select'
import useSupabaseTable from '@/hooks/supabase-table'
import { QueryKeys } from '@/hooks/queryKeys'
import { showToastError } from '@/utils/messages'
import { IconWrapper } from '@/src/stories/IconWrapper'
import { ArrowLeft, Users01 } from '@untitled-ui/icons-react'
import React from 'react'
import { SpinnerSizes } from '@/src/ui/spinner/constants'
import { TextField } from '@/src/stories/TextField'
import Checkbox from '@/src/stories/Checkbox'
import { Enums, TablesInsert } from '@/db_types'
import DateField from '@/src/stories/DateField'
import { format } from 'date-fns'
import { DateValue } from 'react-aria-components'
import { getLocalTimeZone } from '@internationalized/date'

type FormInviteUserProps = {
  onSuccess: () => void
  onCancel: () => void
}

enum STEPS {
  GENERAL,
  TEAM
}

const FormInviteUser = ({ onSuccess, onCancel }: FormInviteUserProps) => {
  const { selectedOrganizationId } = useOrgContext(state => ({
    selectedOrganizationId: state.id
  }))

  const [step, setStep] = React.useState<STEPS>(STEPS.GENERAL)
  const [loading, setLoading] = React.useState(false)

  const { handleSubmit, setValue, watch } = useForm<
    Omit<TablesInsert<'invitations'>, 'start_date'> & { start_date: DateValue }
  >({
    defaultValues: {
      name: '',
      email: '',
      team_role: 'member',
      role: 'user',
      full_view_access: false
    }
  })

  const { items: teams } = useSupabaseTable<Team, TeamInsert>({
    tableName: 'teams',
    queryKeys: [QueryKeys.teams.get, 'team-select'],
    selectQuery: '*'
  })

  const inviteUser = async (
    data: Omit<TablesInsert<'invitations'>, 'start_date'> & {
      start_date: DateValue
    }
  ) => {
    if (!selectedOrganizationId) {
      throw new Error(t`No organization selected`)
    }
    setLoading(true)
    const response = await fetch(`/api/emails/invite_user`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name: data.name,
        email: data.email,
        organization_id: selectedOrganizationId,
        team_role: data.team_role,
        role: data.role,
        full_view_access: data.full_view_access,
        team_id: data.team_id,
        start_date: format(
          data.start_date.toDate(getLocalTimeZone()),
          'yyyy-MM-dd '
        )
      })
    })
    setLoading(false)
    if (!response.ok) {
      showToastError(new Error(t`Failed to invite user`))
    }
    onSuccess()
  }

  if (!teams) {
    return null
  }

  if (loading) {
    return (
      <div className='absolute top-0 left-0 pointer-events-none flex justify-center items-center h-full w-full'>
        <Spinner size={SpinnerSizes.M} />
      </div>
    )
  }

  return (
    <form
      onSubmit={handleSubmit(inviteUser)}
      className='flex flex-col gap-6 w-full h-full'
      noValidate>
      {step === STEPS.GENERAL && (
        <>
          <TextField
            label='Name *'
            isInvalid={!!watch('name') && !watch('name')?.trim()}
            isRequired
            value={watch('name') ?? undefined}
            onChange={value => setValue('name', value)}
          />

          <TextField
            label='Email *'
            isInvalid={!!watch('email') && !watch('email').trim()}
            isRequired
            value={watch('email')}
            type='email'
            onChange={value => setValue('email', value)}
          />

          <Select
            label={t`Company Role *`}
            isRequired
            selectedKey={watch('role')}
            onSelectionChange={v => setValue('role', v as Enums<'org_role'>)}
            items={[
              { id: 'user', name: t`User` },
              { id: 'supervisor', name: t`Supervisor` },
              { id: 'admin', name: t`Admin` }
            ]}>
            {item => <SelectItem label={item.name} id={item.id} />}
          </Select>

          <DateField
            aria-label={t`Start day *`}
            isRequired
            isInvalid={!watch('start_date')}
            value={watch('start_date')}
            label={t`Start day *`}
            onChange={value => setValue('start_date', value)}
          />
        </>
      )}

      {step === STEPS.TEAM && (
        <>
          <Select
            isRequired
            label={t`Team *`}
            placeholder={t`Select a team`}
            iconLeading={<IconWrapper width={16} Icon={Users01} />}
            selectedKey={watch('team_id')}
            items={teams}
            onSelectionChange={v => setValue('team_id', Number(v))}>
            {item => <SelectItem label={item.name} id={item.id} />}
          </Select>

          <Checkbox
            isSelected={watch('team_role') === 'manager'}
            onChange={value =>
              setValue('team_role', value ? 'manager' : 'member')
            }>
            <Checkbox.Label>{t`Add as manager`}</Checkbox.Label>
            <Checkbox.SupportiveText>{t`Give this user manager permissions`}</Checkbox.SupportiveText>
          </Checkbox>

          {(!watch('team_role') || watch('team_role') === 'member') && (
            <Checkbox
              isSelected={!!watch('full_view_access')}
              onChange={value => setValue('full_view_access', value)}>
              <Checkbox.Label>{t`Give Full View`}</Checkbox.Label>
              <Checkbox.SupportiveText>{t`Do you want to let this user see all team data?`}</Checkbox.SupportiveText>
            </Checkbox>
          )}
        </>
      )}

      {step === STEPS.GENERAL && (
        <div className='flex justify-end mt-8 gap-2'>
          <Button
            onPress={onCancel}
            hierarchy={'secondary'}
            label={t`Discard`}></Button>

          <Button
            isDisabled={
              !watch('name') ||
              !watch('email') ||
              !watch('role') ||
              !watch('start_date')
            }
            type={
              watch('role') === 'admin' || watch('role') === 'supervisor'
                ? 'submit'
                : 'button'
            }
            onPress={() => {
              if (watch('role') !== 'admin' && watch('role') !== 'supervisor') {
                setStep(STEPS.TEAM)
              }
            }}
            label={
              watch('role') === 'admin' || watch('role') === 'supervisor'
                ? t`Invite`
                : t`Next`
            }></Button>
        </div>
      )}

      {step === STEPS.TEAM && (
        <div className='flex justify-end mt-8 gap-2'>
          <Button
            iconLeading={<ArrowLeft width={16}></ArrowLeft>}
            onPress={() => setStep(STEPS.GENERAL)}
            hierarchy={'secondary'}
            label={t`Back`}></Button>

          <Button
            isDisabled={!watch('team_id')}
            type='submit'
            label={t`Invite`}></Button>
        </div>
      )}
    </form>
  )
}

export default FormInviteUser
